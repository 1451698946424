import { ref, reactive } from 'vue'
import { Toast } from 'vant'
import utils from '@/utils'
import timeTypeList from './timeType'

export default () => {
  const time = reactive({
    endDate: '',
    startDate: '',
    timeIndex: 0,
    timeTitle: '全部时间',
    timePicker: false,
    timeWhen: false,
    flag: 'startDate'
  })
  const show_time = ref('')
  function validTime(start, end, type) {
    const startDate = new Date(start).getTime()
    const endDate = new Date(end).getTime()
    const gap = endDate - startDate
    if (gap < 0) {
      Toast(type === 'startDate' ? '开始时间必须小于/等于结束时间' : '结束时间必须大于/等于开始时间')
      return false
    }
    return true
  }
  function onConfirmTime(val) {
    let year = val.getFullYear() //年
    let month = val.getMonth() + 1 //月
    let day = val.getDate() //日
    if (month >= 1 && month <= 9) {
      month = `0${month}`
    }
    if (day >= 1 && day <= 9) {
      day = `0${day}`
    }
    let type = time.flag
    let value = `${year}-${month}-${day}`
    switch (type) {
      case 'startDate':
        if (validTime(value, time.endDate, type)) {
          time.startDate = value
        }
        break
      case 'endDate':
        if (validTime(time.startDate, value, type)) {
          time.endDate = value
        }
        break
      default:
        break
    }
    time.timeWhen = false
  }
  function onCancelTime() {
    time.flag = ''
    time.timeWhen = false
  }

  function onConfirm(item) {
    const type = item.value
    const { startDate, endDate } = utils.computedTime(type)
    time.timeTitle = item.title
    time.startDate = startDate
    time.endDate = endDate
    time.timeIndex = timeTypeList.findIndex(item1 => item1.title == time.timeTitle)
    time.timePicker = false
  }

  function onCancel() {
    time.timePicker = false
  }

  function timePickerShow() {
    time.timePicker = true
    time.flag = ''
    if (time.timeTitle == '全部时间') {
      time.timeIndex = 0
    }
  }
  function timeChangeShow(flag) {
    time.flag = flag
    let currentYear = getNowFormatDate()
    show_time.value = new Date(time[time.flag] || currentYear)
    time.timeWhen = true
  }
  function getNowFormatDate() {
    var date = new Date()
    var seperator1 = '-'
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var strDate = date.getDate()
    if (month >= 1 && month <= 9) {
      month = '0' + month
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate
    return currentdate
  }

  return { time, show_time, onConfirmTime, onCancelTime, onConfirm, onCancel, timePickerShow, timeChangeShow }
}
