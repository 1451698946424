<template>
  <div class="vip-item" @click="toDetail">
  <div class="vip-header">
    <div class="vip-img">
      <img class="vip-img" :src="item.avatar_url||$config.defaultLogo"/>
    </div>
    <div class="vip-info">
      <div class="vip-name">
        <span class="vip-name-txt"> {{item?.nickname}}</span>
        <span class="vip-name-txt2"> {{item?.level_name}}</span>
      </div>
      <div class="vip-txt">{{item.mobile}}</div>
    </div>
    <div class="vip-num">
      <div class="vip-name">{{item.man_txt}}</div>
      <div class="vip-txt">{{item.man_num}}</div>
    </div>
  </div>
  <div class="vip-body">
    <div class="vip-body-it" v-for="(subitem,index) in item.list" :key="index">
      <span class="vip-body-txt">
        {{subitem.name}}
      </span>
      <span class="vip-body-num">{{subitem.value}}</span>
    </div>
  </div>
</div>
</template>
<script>
export default {
  components: {
  },
  props: {
    item: Object
  },
  emits:['toDetail'],
  setup(props, { emit }) {
    const toDetail = ()=> {
      emit('toDetail',props.item.user_id)
    }
    return {
      toDetail
    }
  }
}
</script>
<style lang="less" scoped>
  .vip-item{
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 15px;
}
.vip-header{
  display: flex;
  align-items: center;
}
.vip-body{
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0;
}
.vip-body-it{
  width: 46%;
}
.vip-body-it:nth-of-type(2n){
  box-sizing: border-box;
  margin-left: 8%
}
.vip-body-txt{
  display: inline-block;
  width: 45%;
  margin-bottom: 12px;
  font-size: 12px;
  color: #333;
  font-weight: 400;
}
.vip-body-num{
  display: inline-block;
  width: 55%;
  margin-bottom: 2px;
  font-size: 12px;
  color: #999;
  font-weight: 400;
}
.vip-li__it{
  background-color: #FFFFFF;
}
.vip-li__it +.vip-li__it {
  margin-top: 8px;
}
.vip-body-it:nth-of-type(odd){
  border-right: 1px solid #f5f6f7;
  box-sizing: border-box;
}
.vip-body-it:first-of-type{
  border-right: 1px solid transparent;
}
.vip-body-it:last-of-type{
  border-right: 1px solid transparent;
}
.vip-img{
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 12px;
}
.vip-info,.vip-num{
   min-height: 48px;
   padding: 5px 0;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.vip-info{
 flex: 1;
}
.vip-num{
  align-items: center;
}
.vip-name-txt2{
  margin-left: 10px;
        display: inline-block;
        padding: 0 6px;
        line-height: 18px;
        font-size: 11px;
        color: #FFEFBC;
        border-radius: 2px;
        height: 18px;
        line-height: 18px;
        background: #000;
}
.vip-name{
  margin-bottom: 4px;
}
.vip-txt{
  color: #999;
  line-height: 1.6;
}
</style>
