<template>
  <div class="container">
    <SimpleHeader :name="title" />

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <div class="teamw">
        <div class="searchBlock">
          <div class="sift-box">
            <div class="sift-box--time">
              <div class="sift-item pr-20px font-w flex--c arrow" @click="pickerShow('levelType')">
                <div class="sift-item-title">{{ levelTitle }}</div>
              </div>
              <div class="sift-item fcontentCenter" @click="timeChangeShow('startDate')">
                <div :class="{ hasValue: time.startDate != '' }">{{ time.startDate || '开始时间' }}</div>
              </div>
              <div class="sift-item fcontentCenter" @click="timeChangeShow('endDate')">
                <div :class="{ hasValue: time.endDate != '' }">{{ time.endDate || '结束时间' }}</div>
              </div>
            </div>
          </div>
          <div class="sift-box">
            <div class="sift-box--time">
              <div class="sift-item pr-20px font-w flex--c arrow" @click="pickerShow('sortType')">
                <div class="sift-item-title">{{ sortTitle }}</div>
              </div>
              <div class="sift-item flex--s search-input">
                <input
                  v-model="search"
                  @keyup.enter="onSearch"
                  class="input"
                  type="text"
                  placeholder="姓名/手机号"
                  placeholder-class="placeholderStyle"
                />
              </div>
            </div>
          </div>
        </div>
        <van-search v-model="goods" @search="onSearch" placeholder="请输入商品名称/ID查询" />
        <!-- 列表加载事件 -->
        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="getData"
          offset="100"
          :immediate-check="false"
        >
          <div class="attention">
            <TeamItem v-for="(item,index) in list" :key="index" :item="item" @toDetail="toDetail"></TeamItem>
            <Empty :image="true" :show="!loading&&list.length==0"></Empty>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
        <div class="popBox" v-show="time.timeWhen">
          <van-datetime-picker
            v-model="show_time"
            class="popBottom"
            type="date"
            title="选择时间"
            @confirm="onConfirmTime"
            @cancel="onCancelTime"
          />
        </div>
        <div class="popBox" v-show="toast">
          <van-picker
            class="popBottom"
            title="请选择"
            :default-index="pickerIndex"
            show-toolbar
            value-key="name"
            :columns="pickerList"
            @confirm="onConfirmToast"
            @cancel="onCancelToast"
          />
        </div>
  </div>
</template>
<script>
import { reactive, onMounted, inject, toRefs, watch } from 'vue'
import useTime from '@/utils/time'
import { useRoute } from 'vue-router'
import { List, PullRefresh, Tab, Tabs, Toast } from 'vant'
import SimpleHeader from '@/components/SimpleHeader'
import timeTypeList from '@/utils/timeType'
import usePage from '@/hooks/usePage'
import utils from '@/utils'
import TeamItem from './components/TeamItem'
import { teamMemberList, getAgentLevelSetting,getTeamDetail,getTeamOptions } from '@/api/team'
import {setLocal, getLocal } from '@/common/js/utils'
import to from 'await-to-js';
export default {
  name: 'teamDetail',
  components: {
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    SimpleHeader,
    TeamItem
  },
  setup() {
    const _appLoadFanish = inject('appLoadFanish');
    const {
      time,
      show_time,
      onConfirmTime,
      onCancelTime,
      timeChangeShow,
      timePickerShow,
      onConfirm,
      onCancel
    } = useTime()
    const { query } = useRoute()
    const { navigateTo } = usePage()
    let state = reactive({
      userId: '',
      search:'',
      goods:'',
      timeTypeList,
      limitDate: utils.computedTime('today').endDate,
      hasMore:true,
      page:1,
      size:20,
      total:-1,
      list: [],
      levelList:[],
      sortList:[],
      error: false,
      loading: false,
      finished: false,
      refreshing: false,
      finishedText:'',
      toast:false,
      levelTitle:'全部代理',
      levelType:0,
      sortTitle:'全部',
      sortType:0,
      pickerList:[],
      pickerType:'',
      pickerIndex:0,
      amount:'0.00',
      title:'团队明细'
    })
    watch(
      () => [time.startDate, time.endDate],
      () => {
        onSearch()
      }
    )
    const onSearch = () => {
      state.page = 1
      state.list = []
      getData()
    }

    const getOptions = async () => {
      let [error,result] = await to(getTeamOptions())
      if(error) return
      let {code,res,msg} = result
      if (code === 0) {
        state.levelList = res.level.map(v=>{
          return {
            name:v.levelName,
            value:v.level
          }
        })
        state.sortList = res.sortType
      }
    }

    const getData = async () => {
      if (!state.loading) {
        state.loading = true;
      }
      if (state.refreshing) {
          state.list = [];
          state.refreshing = false;
      }
      let params = {
        start: time.startDate,
        end: time.endDate,
        page:state.page,
        level:state.levelType,
        sort:state.sortType,
        user_id: state.userId,
        search:state.search,
        goods:state.goods,
      }
      let [error,result] = await to(getTeamDetail(params));
      _appLoadFanish()
      state.loading = false
      if(error) {
        state.finished = true;
        state.finishedText = ''
        return
      };
      let {code,res,msg} = result;
      if (code == 0) {
        state.list = state.page === 1 ? res.data : state.data.concat(res.data)
        state.hasMore = res.hasMore
        state.total = ~~res.totalNum
        state.finishedText = state.page === 1 && state.list.length == 0 ? '' : `当前共${state.list.length}条数据，已全部加载完`
        if (state.page === 1) {
          state.amount = res.allAmount
        }
        if (!state.hasMore) {
          state.finished = true;
        } else {
          state.page++
        }
      }

    };

    const onRefresh = () => {
      state.loading = true;
      initData()
      getData();
    };



    //重置
    function initData() {
      state.list = []
      state.page = 1
      state.error = false
      state.finished = false
    }

    function pickerShow(type) {
      state.toast = true
      state.pickerType = type
      if (type == 'levelType') {
        state.pickerList =  state.levelList
        state.pickerIndex =  state.levelList.findIndex(item => item.name ==  state.levelTitle)
      } else {
        state.pickerList =  state.sortList
        state.pickerIndex =  state.sortList.findIndex(item => item.name ==  state.sortTitle)
      }
    }

    async function onConfirmToast(item) {
      if (state.pickerType == 'levelType') {
        state.levelTitle = item.name
        state.levelType = item.value
      } else {
        state.sortType = item.value
        state.sortTitle = item.name
      }
      onSearch()
      state.pickerType = ''
      state.toast = false
    }

    function onCancelToast() {
      state.pickerType = ''
      state.toast = false
    }


    const tips = () => {
      Toast('敬请期待')
    }

    const toDetail = id => {
      navigateTo(`/team?id=${id}`)
    }
    const initSort = (type) => {
      let defaultSort = state.sortList.find(item => item.value == type)
      state.sortTitle = defaultSort ? defaultSort.name : state.sortTitle
      state.sortType = type
    }

    onMounted(async () => {
      await getOptions()
      initSort(0)
      if (query && query.hasOwnProperty('id')) {
        state.userId = query.id
      }
      await getData()
    })

    return {
      ...toRefs(state),
      onSearch,
      getData,
      onRefresh,
      pickerShow,
      onConfirm,
      onCancel,
      onConfirmToast,
      onCancelToast,
      time,
      show_time,
      timeChangeShow,
      timePickerShow,
      onCancelTime,
      onConfirmTime,
      toDetail
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../common/style/searchpicker';

.container {
  background: #f8f8f8;
  min-height: 100%;
}
.van-search{
  padding:8px 15px;
}
.searchBlock {
  padding: 15px 15px 0;
  height: 95px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
}
.sift-box {
  margin-bottom: 8px;
}

.search-input {
  // width: 228px !important;
  flex: 1;
  box-sizing: border-box;
  padding-left: 33px;
  > input {
    height: 36px;
    box-sizing: border-box;
    padding: 8px 0;
  }
  &:before {
    content: '';
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url('https://x.jzbull.com/x_dsx_wxapp/icons/gray-search.png') no-repeat;
    background-size: 100%;
  }
}
.teamw {
  background: #f8f8f8;
}

</style>
