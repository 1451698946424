const timeTypeList = [
  {
    title: '全部时间',
    value: 'all'
  },
  {
    title: '今天',
    value: 'today'
  },
  {
    title: '昨天',
    value: 'yesterday'
  },
  {
    title: '本周',
    value: 'curWeek'
  },
  {
    title: '本月',
    value: 'curMonth'
  },
  {
    title: '上月',
    value: 'preMonth'
  },
  {
    title: '近30天',
    value: 'recentThirtyDay'
  },
  {
    title: '今年',
    value: 'curYear'
  },
  {
    title: '去年',
    value: 'preYear'
  }
]

export default timeTypeList
