import {post,get} from '../utils/axios'
// 团队信息
export function getTeamInfo(params) {
  return post('team/getInfo', params)
}
// 团队筛选条件
export function getTeamOptions(params) {
  return post('team/getOptions', params)
}
// 团队明细
export function getTeamDetail(params) {
  return post('team/getDetail', params)
}

// 我的团队明细
export function teamMemberList(params) {
  return post('/user/apiUserAh5Service/teamMemberList', params)
}
// 我的团队预览
export function teamOverview(params) {
  return post('/user/apiUserAh5Service/teamOverview', params)
}
// 代理等级
export function getAgentLevelSetting() {
  return post('/user/apiUserAh5Service/getAgentLevelSetting')
}
