function checkDataType(payload) {
  const map = {
    '[object String]': 'string',
    '[object Object]': 'object',
    '[object Array]': 'array',
    '[object Number]': 'number',
    '[object Boolean]': 'boolean',
    '[object Null]': 'null',
    '[object Undefined]': 'undefined',
    '[object Function]': 'function',
    '[object Error]': 'error',
    '[object Symbol]': 'symbol',
    '[object RegExp]': 'regexp'
  }
  const t = Object.prototype.toString.call(payload)
  return map[t]
}

function debounce(fn, delay = 500) {
  if (checkDataType(fn) !== 'function') {
    console.error(`${fn} is not a function`)
    return false
  }
  let timer = null
  return function() {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      timer = null
    }, delay)
  }
}

function throttle(fn, delay = 300) {
  if (checkDataType(fn) !== 'function') {
    console.error(`${fn} is not a function`)
    return false
  }
  let timer = null
  return function() {
    if (timer) return
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      timer = null
    }, delay)
  }
}

function unique(arr, key) {
  const map = new Map()
  return arr.filter(item => {
    return !map.has(item[key]) && map.set(item[key], 1)
  })
}
function es6Unique(arr) {
  return Array.from(new Set(arr))
}
// 手机号脱敏
function encryptMobile(val = '') {
  val = val.toString()
  const reg = /(\d{3})\d*(\d{4})/
  return val.replace(reg, '$1****$2')
}

/*
 * @info 给定开始, 结束的时间戳, 返回开始到结束时间的倒计时
 * @param start {Number} 开始时间戳
 * @param end {Number} 结束时间戳
 * @param type {String} s秒  ms毫秒
 * @return {Object} {day, hour, minute, second}
 * */
function getCountDownTime(start, end, type = 's') {
  const diff = end - start
  const num = type === 's' ? 1 : 1000
  if (diff < 0) {
    return {
      day: 0,
      hour: '00',
      minute: '00',
      second: '00'
    }
  }
  const day = Math.floor(diff / num / 3600 / 24)
  const h = Math.floor((diff / num / 3600) % 24)
  const m = Math.floor((diff / num / 60) % 60)
  const s = Math.floor((diff / num) % 60)
  const hour = h > 9 ? h : `0${h}`
  const minute = m > 9 ? m : `0${m}`
  const second = s > 9 ? s : `0${s}`
  return {
    day,
    hour,
    minute,
    second
  }
}

function twoDecimal(num) {
  var result = parseFloat(num)
  if (isNaN(result)) {
    return 0
  }
  result = Math.round(num * 100) / 100
  var s_x = result.toString()
  return s_x
}

function formatDate(date, showDay) {
  if (!date) return date
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (showDay) {
    return year + '-' + month + '-' + day
  } else {
    return year + '-' + month
  }
}

/*
 * @info 获取天数时间戳
 * @param daycount {Number} 天数
 * */
function getDayStamp(daycount) {
  return 24 * 3600 * 1000 * daycount
}

/*
@return {object} {startTime: Date, endTime: Date}
1）今日：当前日期的数据总和。
2）昨日：昨天的数据总和。
3）本周：以星期一为一周的开始，星期日为一周的结束，显示星期一到当天的日期时间段的数据总和。
4）本月：显示当月1号到当天的日期时间段的数据总和。
5）今年：显示今年1月到当前月份的日期时间段的数据总和。
6）全部时间：从系统记录的第一个时间记录开始，到当前时间的数据总和。
*/
function computedTime(type) {
  let startTime, endTime
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth()
  let week = date.getDay()
  let curStamp = new Date().getTime()
  switch (type) {
    case 'today': // 今天
      startTime = endTime = new Date()
      break
    case 'yesterday': // 昨天
      startTime = endTime = new Date(curStamp - getDayStamp(1))
      break
    case 'curWeek': // 本周
      endTime = new Date()
      startTime = new Date(curStamp - getDayStamp(week === 0 ? 6 : week - 1))
      break
    case 'curMonth': // 本月
      startTime = new Date(year, month, 1)
      endTime = new Date()
      break
    case 'preMonth': // 上月
      year = month - 1 >= 0 ? year : year - 1
      month = month - 1 >= 0 ? month - 1 : 11
      startTime = new Date(year, month, 1)
      endTime = new Date(year, month, new Date(year, month + 1, 0).getDate())
      break
    case 'recentThirtyDay': // 近30天
      startTime = new Date(curStamp - getDayStamp(30))
      endTime = new Date()
      break
    case 'curYear': // 今年
      startTime = new Date(year, 0, 1)
      endTime = new Date()
      break
    case 'preYear': // 去年
      startTime = new Date(year - 1, 0, 1)
      endTime = new Date(year - 1, 11, 31)
      break
    case 'all': // 全部时间 (不用传,后台会计算)
      startTime = ''
      endTime = ''
      break
    default:
      break
  }
  return {
    startDate: formatDate(startTime, true),
    endDate: formatDate(endTime, true)
  }
}

const utils = {
  checkDataType,
  debounce,
  throttle,
  encryptMobile,
  getCountDownTime,
  twoDecimal,
  getDayStamp,
  computedTime,
  unique,
  es6Unique
}

export default utils
