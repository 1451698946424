<template>
  <div class="simple-header-box">
    <header v-if="!isInWxapp" class="simple-header van-hairline--bottom">
      <i v-if="!isback" class="nbicon nbfanhui" @click="goBack"></i>
      <i v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
      <div class="simple-header-name">{{ title }}</div>
      <!-- <i class="nbicon nbmore"></i> -->
      <i class="nbicon"></i>
    </header>
  </div>
  <div v-if="!isInWxapp" class="block" />
</template>

<script>
import { ref, onMounted, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import usePage from '@/hooks/usePage'

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    back: {
      type: String,
      default: ''
    },
    noback: {
      type: Boolean,
      default: false
    }
  },
  emits: ['callback'],
  setup(props, ctx) {
    const isback = ref(props.noback)
    const router = useRouter()
    const isInWxapp = ref(false)
    const title = ref(props.name || document.title)
    const { isMiniProgram } = usePage()

    const goBack = () => {
      if (!props.back) {
        router.go(-1)
      } else {
        router.push({ path: props.back })
      }
      ctx.emit('callback')
    }

    onMounted(async () => {
      isInWxapp.value = await isMiniProgram()
    })

    watchEffect(() => {
      console.log('props.name',props.name)

      if (props.name) {
        document.title = props.name
        title.value = props.name
      }
    })

    return {
      goBack,
      isback,
      isInWxapp,
      title
    }
  }
}
</script>

<style lang="less" scoped>
@import '../common/style/base/mixin';
.simple-header-box{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  max-width: 375px;
  height: 44px;
  background: #fff;
}
.simple-header {
  // position: fixed;
  // top: 0;
  // left: 0;
  .fj();
  width: 375px;
  height: 44px;
  line-height: 44px;
  padding: 0 10px;
  color: #252525;
  background: #fff;
  .simple-header-name {
    font-size: 14px;
  }
}
.block {
  display: block;
  height: 44px;
  background: #fff;
}
</style>
